.test{

    align-self: auto;
}

.nbis3-divder__container {
    margin-bottom: 30px;
    margin-top: 20px;
    text-transform: uppercase;
    color: #202223;
    width: 100%;
    height: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    text-align: left;
}

.turbo-divder__box {
    margin-bottom: 10px;
    margin-top: 10px;
    text-transform: uppercase;
    color: #202223;
    width: 100%;
    height: 5px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    text-align: left;
}

.nbis3-divder__text {
    font-size: 13px;
    background-color: #ffffff;
    padding: 0 0px;
    font-weight: bold;
}

.mb-10{
    margin-bottom: 10px;
}

.mt-10{
    margin-top: 10px;
}


.mt-20{
    margin-top: 20px;
}


.mb-20{
    margin-bottom: 20px;
}
.muted-text{
    color: #59626a;
}


.tag-container {
    margin-top: 5px;
}

.tag-container .Polaris-Tag {
    margin-right: 7px;
}


.Turbo-Dasboard--Empty-State-Container{
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 2px;
    margin-top: 35px;
}

.Turbo-Dasboard--Empty-State-Section {
    flex: 1 0 48%;
    margin: 5px;
}


.Turbo-Dasboard--Empty-State-Img {
    object-fit: cover;
    object-position: center;
    height:90%;
    width:90%;
}

.Turbo-Dasboard--Empty-State-Subtilte-Container {
    padding-top: 5px;
    padding-bottom: 30px;
}



.stepper-wrapper {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    padding-bottom: 20px;
}
.stepper-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
}

.stepper-item::before {
    position: absolute;
    content: "";
    border-bottom: 2px solid #ededed;
    width: 100%;
    top: 20px;
    left: 40%;
    z-index: 2;
}

.stepper-item::after {
    position: absolute;
    content: "";
    border-bottom: 5px solid #ededed;
    width: 100%;
    top: 15px;
    left: -50%;
    z-index: 2;
}

.stepper-item .step-counter {
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #B7C0EB;
    margin-bottom: 6px;
}

.stepper-item.active {
    font-weight: bold;
}

.stepper-item.completed .step-counter {
    background-color: #437FEC;
}

.stepper-item.completed::after {
    position: absolute;
    content: "";
    border-bottom: 6px solid #437FEC;
    width: 100%;
    top: 15px;
    left: 50%;
    z-index: 3;

}

.step-counter{

    color:#FFF;
}

/********************************************************************************/



.nbis-zebra-table tr:nth-child(odd) {
    background-color: #f2f2f2;
}

.nbis-zebra-table th, .nbis-zebra-table td {
    /**border: 1px solid #ccc;**/
}

.nbis-zebra-table td {
    white-space: normal !important;
}


.Polaris-DataTable { position: relative; max-width: 100vw; }

.Polaris-DataTable--condensed .Polaris-DataTable__Navigation { display: flex; align-items: center; justify-content: center; width: 100%; padding: 1rem 0.5rem; }

@media (min-width: 48em) { .Polaris-DataTable--condensed .Polaris-DataTable__Navigation { justify-content: flex-end; } }

.Polaris-DataTable__Navigation { display: none; }

.Polaris-DataTable__Pip { height: 0.375rem; width: 0.375rem; background: var(--p-text-subdued); border-radius: var(--p-border-radius-base); }

.Polaris-DataTable__Pip:not(:last-of-type) { margin-right: 0.25rem; }

.Polaris-DataTable__Pip--visible { background: var(--p-text); }

.Polaris-DataTable__ScrollContainer { overflow-x: auto; -webkit-overflow-scrolling: touch; }

.Polaris-DataTable__Table { width: 100%; border-spacing: 0; }

@media (min-width: 48em) { .Polaris-DataTable--hoverable:hover .Polaris-DataTable__Cell { background: var(--p-surface-hovered); } }

.Polaris-DataTable__TableRow + .Polaris-DataTable__TableRow .Polaris-DataTable__Cell { border-top: 0.0625rem solid var(--p-divider); }

.Polaris-DataTable__Cell { padding: 1rem; white-space: nowrap; text-align: left; transition: background-color 0.2s ease-in-out; }

.Polaris-DataTable__Cell--firstColumn { font-weight: 400; color: var(--p-text); text-align: left; white-space: normal; }

.Polaris-DataTable__Cell--numeric { text-align: right; }

.Polaris-DataTable__Cell--truncated { white-space: nowrap; overflow-x: hidden; text-overflow: ellipsis; max-width: 9.0625rem; }

.Polaris-DataTable__Cell--header { font-weight: 400; color: var(--p-text); border-bottom: 0.0625rem solid var(--p-divider); border-top: 0; }

.Polaris-DataTable__Cell--sortable { padding: 0; }

.Polaris-DataTable__Cell--verticalAlignTop { vertical-align: top; }

.Polaris-DataTable__Cell--verticalAlignBottom { vertical-align: bottom; }

.Polaris-DataTable__Cell--verticalAlignMiddle { vertical-align: middle; }

.Polaris-DataTable__Cell--verticalAlignBaseline { vertical-align: baseline; }

.Polaris-DataTable__Icon { display: flex; align-self: flex-end; opacity: 0; transition: opacity 200ms cubic-bezier(0.25, 0.1, 0.25, 1), fill 200ms cubic-bezier(0.25, 0.1, 0.25, 1); }

.Polaris-DataTable__Heading { -webkit-appearance: none; appearance: none; margin: 0; padding: 0; background: none; border: none; font-size: inherit; line-height: inherit; color: inherit; cursor: pointer; position: relative; position: relative; display: inline-flex; justify-content: flex-end; align-items: baseline; color: var(--p-text); transition: color 200ms cubic-bezier(0.25, 0.1, 0.25, 1); cursor: pointer; padding: 0.5rem; margin: 0.5rem; }

.Polaris-DataTable__Heading:focus { outline: none; }

.Polaris-DataTable__Heading::after { content: ''; position: absolute; z-index: 1; top: -0.0625rem; right: -0.0625rem; bottom: -0.0625rem; left: -0.0625rem; display: block; pointer-events: none; box-shadow: 0 0 0 -0.0625rem var(--p-focused); transition: box-shadow 100ms var(--p-ease); border-radius: calc(var(--p-border-radius-base) + 0.0625rem); }

.Polaris-DataTable__Heading svg { fill: var(--p-icon); }

.Polaris-DataTable__Heading:hover { color: var(--p-interactive-hovered); }

.Polaris-DataTable__Heading:hover svg { fill: var(--p-interactive-hovered); }

.Polaris-DataTable__Heading:hover .Polaris-DataTable__Icon { opacity: 1; }

.Polaris-DataTable__Heading:focus:not(:active)::after { box-shadow: 0 0 0 0.125rem var(--p-focused); outline: 0.0625rem solid transparent; }

.Polaris-DataTable__Heading--left { justify-content: flex-start; flex-direction: row-reverse; }

.Polaris-DataTable__Cell--sorted .Polaris-DataTable__Icon { opacity: 1; }

.Polaris-DataTable__Cell--total { font-weight: 600; background: var(--p-surface-subdued); border-bottom: 0.0625rem solid var(--p-divider); }

.Polaris-DataTable--cellTotalFooter { border-top: 0.0625rem solid var(--p-divider); border-bottom: none; border-bottom-left-radius: var(--p-border-radius-base); border-bottom-right-radius: var(--p-border-radius-base); }

.Polaris-DataTable__Footer { padding: 1rem; background: var(--p-surface-subdued); color: var(--p-text-subdued); text-align: center; border-top: 0.0625rem solid var(--p-divider); border-bottom-left-radius: var(--p-border-radius-base); border-bottom-right-radius: var(--p-border-radius-base); }

.Polaris-DisplayText { margin: 0; }

.Polaris-DisplayText--sizeSmall { font-size: 1rem; font-weight: 400; line-height: 1.5rem; }

@media (min-width: 40em) { .Polaris-DisplayText--sizeSmall { font-size: 1.25rem; line-height: 1.75rem; } }

.Polaris-DisplayText--sizeMedium { font-size: 1.3125rem; font-weight: 400; line-height: 1.75rem; }

@media (min-width: 40em) { .Polaris-DisplayText--sizeMedium { font-size: 1.625rem; line-height: 2rem; } }

.Polaris-DisplayText--sizeLarge { font-size: 1.5rem; font-weight: 600; line-height: 1.75rem; }

@media (min-width: 40em) { .Polaris-DisplayText--sizeLarge { font-size: 1.75rem; line-height: 2rem; } }

.Polaris-DisplayText--sizeExtraLarge { font-size: 1.6875rem; font-weight: 600; line-height: 2.25rem; }

.nbis-center {
    text-align: center !important;
}
.nbis-plan-btn-area{
    margin-top: 10px;
}


.nbis-highligted-cell-sides2{
    border-left: 4px solid #437FEC;
    border-right: 4px solid #437FEC;
}

.nbis-highligted-cell-base2{
    border-left: 4px solid #437FEC;
    border-right: 4px solid #437FEC;
    border-bottom: 4px solid #437FEC !important;
}

.nbis-highligted-cell-top2{
    border-left: 4px solid #437FEC;
    border-right: 4px solid #437FEC;
    color: #437FEC !important;
    border-top: 4px solid #437FEC !important;
}

/********************************************************************************/




.dropdown {
    position: relative;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    cursor: pointer;
    text-align: left;
}

.dropdown-selected {
    padding: 10px;
    border-bottom: 1px solid #eee;
}

.dropdown-content {
    position: absolute;
    background-color: #f9f9f9;
    min-width: 100%;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    max-height: 289px;
    overflow-y: auto;
}

.dropdown-item {
    padding: 10px;
    clear: both;
    font-size: 14px;
    color: black;
    text-align: left;
    text-decoration: none;
    display: block;
}

.dropdown-item:hover {
    background-color: #f1f1f1;
}

/* Styling for SVG */
.dropdown-item svg {
    width: 30px;
    height: auto;
    vertical-align: middle;
    margin-right: 8px;
}

.dropdown-selected .chevron {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-left: 5px;
    border-right: 2px solid black;
    border-bottom: 2px solid black;
    transform: rotate(45deg);
    transition: transform 0.3s;
}

.dropdown-selected .chevron.up {
    transform: rotate(-135deg);
}

.dropdown-selected div {
    display: inline-block;
    vertical-align: middle;
    max-width: 100px;
    max-height: 100px;
}

.icon-image-preview{
    width: 35px;
    height: 35px;
}

.preview-theme-container{
    width: 100%;
    text-align: right;
}
.upgrade-container{
    margin-top: 15px;
}


.c-table-editor {
    width: 100%;
    overflow: auto;
    padding: 0 1em 1em 1em;
}

.c-table-editor__delete_icon {
    display: inline-block;
    vertical-align: top;
    svg {
        path {
            fill: #c05717;
        }
    }
}

.c-table-editor__icons {
    vertical-align: middle;
}

.c-table-editor__icons--trash {
    margin: -2px 6px 0 0;
}

.c-table-editor__table {
    padding-top: 30px;
    padding-bottom: 10px;
    padding-left: 30px;
    padding-right: 10px;
    width: 100%;
    border-spacing: 0;
}

.c-table-editor__separator-r {
    height: 10px;
    cursor: pointer;
    position: relative;
}

.c-table-editor__separator-r::after {
    content: '';
    display: block;
    position: absolute;
    top: -5px;
    left: 0px;
    height: 10px;
    width: 100%;
    background: transparent;
    z-index: 600;
}

.c-table-editor__separator-c {
    min-width: 10px;
    cursor: pointer;
    position: relative;
}

.c-table-editor__separator-c::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: -5px;
    width: 10px;
    height: 100%;
    background: transparent;
    z-index: 600;
}

.c-table-editor__separator-r__content {
    height: 4px;
    position: absolute;
    width: 100%;
    top: 3px;
    left: -3px;
    visibility: hidden;
    z-index: 500;
    border-radius: 5px 0 0 5px;
}

.c-table-editor_blank {
    height: 4px;
    position: absolute;
    width: 100%;
    top: 3px;
    left: -3px;
    visibility: hidden;
    z-index: 500;
    border-radius: 5px 0 0 5px;
    height: 18px;
}

.c-table-editor_blank::after {
    height: 18px;
}

.c-table-editor__separator-r__content::after {
    content: '+';
    position: absolute;
    left: -25px;
    top: -11px;
    color: #50b83c;
    font-weight: bold;
    width: 17px;
    height: 18px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    border-radius: 0;
    font-size: 16px;
    background: #fff;
    border: 2px dashed #50b83c;
}

.c-table-editor__separator-c__content {
    width: 4px;
    margin: 0 auto;
    position: absolute;
    height: calc(100% - 10px);
    top: 0px;
    left: 4px;
    visibility: hidden;
    z-index: 500;
    border-radius: 5px 5px 0 0;
}

.c-table-editor__separator-c__content::after {
    content: '+';
    position: absolute;
    bottom: 0;
    left: -7px;
    color: white;
    font-size: 16px;
    font-weight: bold;
    width: 18px;
    height: 18px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    border-radius: 0;
    color: #50b83c;
    background: #fff;
    border: 2px dashed #50b83c;
}

.c-table-editor__separator-c:hover .c-table-editor__separator-c__content {
    visibility: visible;
    border-right: 2px dashed #50b83c;
}

.c-table-editor__separator-r:hover .c-table-editor__separator-r__content {
    margin-top: 1px;
    visibility: visible;
    border-top: 2px dashed #50b83c;
}


.c-table-editor__delete-r,
.c-table-editor__delete-c {
    opacity: .25;
    cursor: pointer;
    color: #c05717;;
    text-align: center;
    transition: 250ms opacity ease-in-out;
}

.c-table-editor:hover .c-table-editor__delete-r,
.c-table-editor:hover .c-table-editor__delete-c {
    opacity: 1;
}

.c-table-editor__data {
    background: transparent;
    border: 0;
    padding: 0;
    min-width: 200px;
}

.c-table-editor__delete-r {
    min-width: 75px;
    text-align: left;
    white-space: nowrap;
}

.c-table-editor__delete-c {
    height: 30px;
}

.c-table-editor__separator-r {
    &--top {
        height: 4px;
    }
}

.c-table-editor__separator-r__content {
    &--top {
        left: 12px;
        top: -3px;
    }
}


.c-table-cell {
    position: relative;
    margin-left: 2px;
}

.c-table-cell--header input {
    font-weight: bold;
}

.c-table-cell__type {
    position: absolute;
    left: 32px;
    top: 7px;
    display: block;
}

.c-table-cell:hover .c-table-cell__type,
.c-table-cell--active .c-table-cell__type {
    display: block;
}

.c-table-cell__type__caret {
    min-height: 0;
    min-width: 0;
    padding: 0;
    border: 0;

    svg {
        path {
            fill: #919eab;
        }
    }
}

.c-table-cell__type__list {
    width: 120px;
}

.c-table-cell .Polaris-Connected {
    position: initial;
    z-index: 0;
}

.c-table-cell .Polaris-Connected__Item {
    position: initial;
    z-index: 0;
}

.c-table-cell .Polaris-ActionList__Item--active {
    background: #5c6ac4;
    outline: none;
    color: #ffffff;
}

.c-table-cell__field__left {
    border: 1px solid #dfe3e8;
    background-color: #f9fafb;
    padding: .6rem 2.2rem .6rem 1rem;
    height: 3.6rem;

    box-shadow: 0 1px 0 rgba(22, 29, 37, 0.05);
    border-radius: 3px;
    border: 2px solid #919eab;
    background-image: linear-gradient(180deg, #ffffff 0%, #f9fafb 100%);
    cursor: pointer;

    svg {
        use {
            fill: #919eab;
        }
    }
}

.c-table-editor__delete-r {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.c-table-editor__delete-r span {
    margin-left: 8px;
}

.inline-flex-center {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.inline-flex-center span {
    margin-left: 2px;
}

.inline-flex-center .Polaris-Icon {
    margin: 2px;
}


.dropdown {
    position: relative;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    cursor: pointer;
    text-align: left;
}

.dropdown-selected {
    padding: 10px;
    border-bottom: 1px solid #eee;
}

.dropdown-content {
    position: absolute;
    background-color: #f9f9f9;
    min-width: 100%;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    max-height: 289px;
    overflow-y: auto;
}

.dropdown-item {
    padding: 10px;
    clear: both;
    font-size: 14px;
    color: black;
    text-align: left;
    text-decoration: none;
    display: block;
}

.dropdown-item:hover {
    background-color: #f1f1f1;
}

/* Styling for SVG */
.dropdown-item svg {
    width: 30px;
    height: auto;
    vertical-align: middle;
    margin-right: 8px;
}

.dropdown-selected .chevron {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-left: 5px;
    border-right: 2px solid black;
    border-bottom: 2px solid black;
    transform: rotate(45deg);
    transition: transform 0.3s;
}

.dropdown-selected .chevron.up {
    transform: rotate(-135deg);
}

.dropdown-selected div {
    display: inline-block;
    vertical-align: middle;
    max-width: 100px;
    max-height: 168px;
    right: 0px;
    top: 40px;
}

.icon-image-preview{
    width: 35px;
    height: 35px;
}

.polaris-table-preview, .polaris-table-preview th, .polaris-table-preview td {
    border: 1px solid black;
    border-collapse: collapse;
}

.polaris-table-preview th {
    padding: 10px;
}

.polaris-table-preview td {
    padding: 10px;
}